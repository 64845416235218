@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

table {
	border-spacing: 0;
	border-collapse: separate;
}

table tr th,
table tr td {
	border-right: 1px solid rgb(22, 163, 74);
	border-bottom: 1px solid rgb(22, 163, 74);
}
table tr th:first-child,
table tr td:first-child {
	border-left: 1px solid rgb(22, 163, 74);
}

table tr th {
	border-top: 1px solid rgb(22, 163, 74);
}

table tr:first-child th:first-child {
	border-top-left-radius: 6px;
}

table tr:first-child th:last-child {
	border-top-right-radius: 6px;
}

table tr:last-child td:first-child {
	border-bottom-left-radius: 6px;
}

table tr:last-child td:last-child {
	border-bottom-right-radius: 6px;
}
